import axios from 'axios';
import { ICaasStudentsParams } from 'src/types/ICaasStudents';
import { ICaasStudentsResponse } from 'src/types/ICaasStudentsResponse';
import { ICreateNewCourseRequest } from 'src/types/ICreateNewCourseRequest';
import { ICreateNewCourseResponse } from 'src/types/ICreateNewCourseResponse';
import { ICreateWebhookParams } from 'src/types/ICreateWebhook';
import { IExportCaasStudentsParams } from 'src/types/IExportCaasStudentsParams';
import { IGetFinancialStatement } from 'src/types/IFinancialStatementResponse';
import { IGetAllCoursesPlatformAPI } from 'src/types/IGetAllCoursesPlatformAPI';
import { IGetConsolidatedReleases } from 'src/types/iGetConsolidatedReleases';
import {
  IGetConversionChartParams,
  IGetConversionChartResponse,
} from 'src/types/IGetConversionChart';
import { IGetEstimatedReleases } from 'src/types/IGetEstimatedReleases';
import {
  IGetExclusiveSales,
  IGetExclusiveSalesResponse,
} from 'src/types/IGetExclusiveSales';
import { IGetFinancialInstitutions } from 'src/types/IGetFinancialInstitutions';
import { IGetFinancialStatementsParams } from 'src/types/IGetFinancialStatementsParams';
import { IGetFinancialStatementSummary } from 'src/types/IGetFinancialStatementSummary';
import { IGetProcessingReleases } from 'src/types/IGetProcessingReleases';
import { IGetSellersChart } from 'src/types/IGetSellersChartData';
import { IGetSellersChartParams } from 'src/types/IGetSellersChartParams';
import { IGetStudentsInfoResponse } from 'src/types/IGetStudentsInfoResponse';
import { IGetWebhookEventsResponse } from 'src/types/IGetWebhookEvents';
import {
  IGetWebhooksResponse,
  IWebhookInstances,
} from 'src/types/IGetWebhooks';
import { ILogin } from 'src/types/ILogin';
import { ILoginResponse } from 'src/types/ILoginResponse';
import { IResetPassword } from 'src/types/IResetPassword';
import { ISalesFunnelResponse } from 'src/types/ISalesFunnelResponse';
import { ISendBankAccountParams } from 'src/types/ISendBankAccountParams';
import { IStudentsInfoFilterParams } from 'src/types/IStudentsInfoFilterParams';
import { IToggleWebhookStatusParams } from 'src/types/IToggleWebhookStatusParams';
import { IUpdateStudentCourseReleased } from 'src/types/IUpdateStudentCourseReleased';
import { IUpdateWebhookParams } from 'src/types/IUpdateWebhookParams';
import { ICreateTrackingParams } from 'src/types/ICreateTracking';
import {
  IGetTrackingsResponse,
  ITrackingInstances,
} from 'src/types/IGetTrackings';
import { IToggleTrackingStatusParams } from 'src/types/IToggleTrackingStatusParams';
import { IUpdateTrackingParams } from 'src/types/IUpdateTrackingParams';

export const facebookMetaGraph = axios.create({
  baseURL: process.env.NEXT_PUBLIC_FACEBOOK_META_GRAPH_API,
  withCredentials: false,
});

export const platformAPI = axios.create({
  baseURL:
    process.env.PLATFORM_API_URL || process.env.NEXT_PUBLIC_PLATFORM_API_URL,
  withCredentials: false,
});

platformAPI.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

platformAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const createLogin = async (
  loginData: ILogin,
): Promise<ILoginResponse> => {
  const { data } = await platformAPI.post('/auth', loginData);

  return data;
};

export const createForgotPassword = async (email: string): Promise<void> => {
  const { data } = await platformAPI.post('/users/forgot_password', { email });

  return data;
};

export const createResetPassword = async (
  resetPasswordData: IResetPassword,
): Promise<void> => {
  const { data } = await platformAPI.post(
    '/users/reset_password',
    resetPasswordData,
  );

  return data;
};

export const getStudentsInfo = async (
  filterParams: IStudentsInfoFilterParams,
): Promise<IGetStudentsInfoResponse> => {
  const {
    page,
    limit,
    effectiveDates,
    status,
    productType,
    cancellations,
    chargebacks,
    key,
    search,
    delayedDays,
    delayedInstallments,
    currentInstallments,
    paidInstallments,
    signal,
    releaseDates,
    isCheckedClearRelease,
    courseIds,
    campaigns,
  } = filterParams;

  const formattedParams = {
    page,
    limit,
    status,
    productType,
    cancellations,
    chargebacks,
    courseId: courseIds,
    campaigns,
    delayedDays: [delayedDays],
    delayedInstallments: [delayedInstallments],
    currentInstallments: [currentInstallments],
    paidInstallments: [paidInstallments],
    ...(effectiveDates.every((date) => date != null)
      ? {
          startEffectiveDate: effectiveDates?.[0],
          endEffectiveDate: effectiveDates?.[1],
        }
      : {}),
    ...(isCheckedClearRelease
      ? {
          startReleaseDate: 'CLEAR',
          endReleaseDate: 'CLEAR',
        }
      : releaseDates.every((date) => date != null)
      ? {
          startReleaseDate: releaseDates?.[0],
          endReleaseDate: releaseDates?.[1],
        }
      : {}),

    ...(search.length ? { [key]: search } : {}),
  };

  const { data } = await platformAPI.get('/students', {
    params: formattedParams,
    signal,
  });

  return data;
};
export const updateStudentCourseReleased = async (
  id: number,
  date: string | null,
): Promise<IUpdateStudentCourseReleased> => {
  const { data } = await platformAPI.put(`/students/${id}`, {
    date,
  });
  return data;
};
export const createNewCourse = async (
  body: ICreateNewCourseRequest,
): Promise<ICreateNewCourseResponse> => {
  const { data } = await platformAPI.post('/courses', body);

  return data;
};

export const getSalesFunnel = async (
  CreditRequestId: string,
): Promise<ISalesFunnelResponse> => {
  const data = await platformAPI.get(`/sales/funnel/${CreditRequestId}`);

  return data;
};

export const getFinancialStatementSummary =
  async (): Promise<IGetFinancialStatementSummary> => {
    const { data } = await platformAPI.get('/financial-statement/summary');

    return data;
  };

export const getConsolidatedReleases = async ({
  page,
  limit,
  transactionType,
  selectedDates,
  signal,
}: IGetConsolidatedReleases): Promise<IGetFinancialStatement> => {
  const params = {
    page,
    limit,
    transactionType,
    selectedDates,
  };

  const { data } = await platformAPI.get(
    '/financial-statement/consolidated-releases',
    { params, signal },
  );

  return data;
};
export const getFinancialStatement = async ({
  page,
  limit,
  from,
  to,
  releasedStatus,
  productTypes,
  types,
  transactionReferenceType,
  creditRequests,
  signal,
  order,
}: IGetFinancialStatementsParams): Promise<IGetFinancialStatement> => {
  const params = {
    page,
    limit,
    from,
    to,
    releasedStatus,
    productTypes,
    ...(types && { types: types }),
    transactionReferenceType,
    creditRequests,
    order,
  };

  const { data } = await platformAPI.get('/financial-statement', {
    params,
    signal,
  });
  return data;
};

export const getProcessingReleases = async ({
  page,
  limit,
  selectedDates,
}: IGetProcessingReleases): Promise<IGetFinancialStatement> => {
  const params = {
    page,
    limit,
    selectedDates,
  };

  const { data } = await platformAPI.get(
    '/financial-statement/processing-releases',
    { params },
  );

  return data;
};

export const getEstimatedReleases = async ({
  page,
  limit,
  selectedDates,
}: IGetEstimatedReleases): Promise<IGetFinancialStatement> => {
  const params = {
    page,
    limit,
    selectedDates,
  };

  const { data } = await platformAPI.get(
    '/financial-statement/estimated-releases',
    { params },
  );

  return data;
};

export const getAllCourses = async () => {
  const { data } = await platformAPI.get<IGetAllCoursesPlatformAPI>(
    '/courses?listAll=true',
  );

  return data;
};

export const getSellersChart = async (
  {
    emails,
    productType,
    startEffectiveDate,
    endEffectiveDate,
  }: IGetSellersChartParams,
  signal?: AbortSignal,
) => {
  const { data } = await platformAPI.get<{ data: IGetSellersChart }>(
    '/sales/summary',
    {
      params: {
        ...(emails?.length ? { emails } : {}),
        ...(productType ? { productTypes: productType } : {}),
        ...(startEffectiveDate && endEffectiveDate
          ? { effectiveDates: [startEffectiveDate, endEffectiveDate] }
          : {}),
      },
      signal,
    },
  );

  data?.data?.data?.forEach((item) => {
    data?.data?.sellers?.forEach((seller) => {
      if (
        `${seller}|totalQty` in item === false ||
        `${seller}|totalValue` in item === false
      ) {
        item[`${seller}|totalQty`] = 0;
        item[`${seller}|totalValue`] = 0;
      }
    });
  });

  return data?.data;
};

export const getAllSellers = async (signal?: AbortSignal) => {
  const { data } = await platformAPI.get<{ data: string[] }>('/sales/sellers', {
    signal,
  });

  return data?.data;
};

export const getExclusiveSales = async ({
  page,
  status,
  cpf,
  signal,
}: IGetExclusiveSales) => {
  const { data } = await platformAPI.get<IGetExclusiveSalesResponse>(
    '/sales/exclusive',
    {
      params: {
        page,
        status,
        ...(cpf ? { CPF: cpf } : {}),
      },
      signal,
    },
  );

  return data;
};

export const getFinancialInstitutions =
  async (): Promise<IGetFinancialInstitutions> => {
    const { data } = await platformAPI.get('/bank-account/domain');

    return data;
  };

export const sendBankAccount = async (params: ISendBankAccountParams) => {
  const { data } = await platformAPI.put('/bank-account', params);

  return data;
};

export const getConversionChart = async (
  params: IGetConversionChartParams,
  signal?: AbortSignal,
): Promise<IGetConversionChartResponse> => {
  const { data } = await platformAPI.get('/conversion-dashboard', {
    params,
    signal,
  });

  return data;
};

export const getCaasStudents = async ({
  params,
  signal,
}: ICaasStudentsParams) => {
  const { data } = await platformAPI.get<ICaasStudentsResponse>(
    '/dynamic-product-dashboard',
    {
      params,
      signal,
    },
  );

  return data;
};

export const exportCaasStudentsInfo = async (
  params: IExportCaasStudentsParams,
): Promise<BlobPart> => {
  const { data } = await platformAPI.post(
    '/export/dynamic-product-dashboard',
    params,
  );

  return data;
};

export const getWebhooks = async () => {
  const { data } = await platformAPI.get<IGetWebhooksResponse>('/webhook');

  return data;
};

export const getWebhookEvents = async (signal?: AbortSignal) => {
  const { data } = await platformAPI.get<IGetWebhookEventsResponse>(
    '/webhook-events',
    {
      signal,
    },
  );

  return data;
};

export const createWebhook = async (params: ICreateWebhookParams) => {
  const { data } = await platformAPI.post<{ data: IWebhookInstances }>(
    '/webhook',
    params,
  );

  return data;
};

export const updateWebhook = async (params: IUpdateWebhookParams) => {
  const { data } = await platformAPI.patch(`/webhook/${params?.id}`, {
    configurationName: params.configurationName,
    enabledEvents: params.enabledEvents,
    webhookUrl: params.webhookUrl,
    authorizationToken: params?.authorizationToken || undefined,
  });

  return data;
};

export const toggleWebhookStatus = async ({
  id,
  active,
}: IToggleWebhookStatusParams) => {
  const { data } = await platformAPI.patch(`/webhook/${id}`, {
    active,
  });

  return data;
};

export const deleteWebhook = async (id: number) => {
  const { data } = await platformAPI.delete(`/webhook/${id}`);

  return data;
};

export const sendWebhookTest = async (webhookId: number) => {
  const { data } = await platformAPI.post(`/webhook/test/${webhookId}`);

  return data;
};

export const getTrackings = async (PartnerId: number) => {
  const { data } = await platformAPI.get<IGetTrackingsResponse>(
    `/tracking/${PartnerId}`,
  );
  return data;
};

export const createTracking = async (params: ICreateTrackingParams) => {
  const { data } = await platformAPI.post<{ data: ITrackingInstances }>(
    '/tracking',
    params,
  );

  return data;
};

export const updateTracking = async (params: IUpdateTrackingParams) => {
  const { data } = await platformAPI.patch(`/tracking/${params?.id}`, {
    PartnerId: params?.PartnerId,
    type: params?.type,
    credentials: params?.credentials ?? undefined,
    active: params?.active,
    enabledEvents: params?.enabledEvents,
  });

  return data;
};

export const toggleTrackingStatus = async ({
  id,
  PartnerId,
  active,
}: IToggleTrackingStatusParams) => {
  const { data } = await platformAPI.patch(`/tracking/${id}`, {
    PartnerId,
    active,
  });

  return data;
};

export const deleteTracking = async ({ id, PartnerId }: any) => {
  const { data } = await platformAPI.delete(`/tracking/${id}`, {
    headers: {
      PartnerId,
    },
  });

  return data;
};

export const sendTrackingTest = async (trackingId: number) => {
  const { data } = await platformAPI.post(`/tracking/test/${trackingId}`);

  return data;
};

export const validateTrackingToken = async (token: string) => {
  const { data } = await facebookMetaGraph.get('/v16.0/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
